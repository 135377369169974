// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { colors } from "./../../utils/theme/colors"

const Pagination = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages,
  } = pageContext

  return (
    <nav
      role="navigation"
      sx={{
        display: "flex",
        justifyContent: "center",
        my: "35px",
      }}
    >
      <div>
        {previousPagePath && (
          <Link
            sx={{
              fontSize: "1.2rem",
              textAlign: "center",
              color: colors.code.primary,
              textDecoration: "none",
            }}
            to={previousPagePath}
            rel="prev"
          >
            Previous
          </Link>
        )}
      </div>
      {numberOfPages > 1 && (
        <div
          sx={{
            fontSize: "1.2rem",
            textAlign: "center",
            color: "#818082",
            mx: "15px",
          }}
        >
          Page {humanPageNumber} of {numberOfPages}
        </div>
      )}
      <div
        sx={{
          fontSize: "0.938rem",
          textAlign: "center",
          color: "#818082",
        }}
      >
        {nextPagePath && (
          <Link
            sx={{
              fontSize: "1.2rem",
              textAlign: "center",
              color: colors.code.primary,
              textDecoration: "none",
            }}
            to={nextPagePath}
            rel="next"
          >
            Next
          </Link>
        )}
      </div>
    </nav>
  )
}

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Pagination
