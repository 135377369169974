// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import typography from "../../utils/typography"

const Header = () => {
  return (
    <div
      sx={{
        height: ["65vh", "65vh", "45vh", "45vh"],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "3.1rem",
      }}
    >
      <div sx={{ display: "flex" }}>
        <h2
          sx={{
            background: `linear-gradient(180deg, transparent 50%, rgba(255, 164, 27, 0.7) 50%)`,
            position: "relative",
            height: "fit-content",
            marginBottom: 0,
            marginRight: "1rem",
            fontFamily: typography.headerFontFamily,
            fontWeight: 900,
            fontSize: ["1.625rem", "1.625rem", "3.5rem", "3.5rem"],
          }}
        >
          Latest News
        </h2>
        <h2
          sx={{
            marginBottom: 0,
            fontSize: ["1.625rem", "1.625rem", "3.5rem", "3.5rem"],
          }}
        >
          {"&"}
        </h2>
      </div>
      <h2
        sx={{
          position: "relative",
          fontSize: ["1.625rem", "1.625rem", "3.5rem", "3.5rem"],
          display: "block",
          marginBottom: 0,
          fontFamily: typography.headerFontFamily,
          background: `linear-gradient(180deg, transparent 50%, rgba(255, 164, 27, 0.7) 50%)`,
          fontWeight: 900,
        }}
      >
        Updates
      </h2>
    </div>
  )
}

export default Header
