// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout/layout"
import MostPopular from "../components/blog/most-popular"
import ServicesDeSeelk from "../components/blog/services-de-seelk"
import Container from "../components/layout/container"
import LatestArticles from "../components/blog/latest-articles"
import MetaDataQuery from "../components/common/meta/MetaData"
import Slugs from "../components/blog/slugs"
import Header from "../components/blog/header"
import Partciles from "../../static/bg.png"
import Pagination from "../components/common/pagination"
import { blogTags } from "../utils/siteConfig"
import Newsletter from "../components/blog/newsletter"

const Index = ({ data, location, pageContext }) => {
  const { allContentfulFooter } = data

  const { skip, limit, lang } = pageContext
  let posts =
    data.allGhostPost.edges
      .map(item => item.node)
      .filter(post => post.tags.map(tag => tag.slug).includes(lang))
      .map(post => ({
        ...post,
        primary_tag: post.tags.find(tag => blogTags.includes(tag.slug)),
      })) || []
  const mostPopularPosts = posts
    .filter(post => post.tags.some(tag => tag.slug === "most-popular"))
    .slice(0, 4)
  posts = posts.slice(skip, skip + limit)
  const latestPosts = posts.slice(0, 4)
  const otherPosts = posts.slice(4)
  const tags = data.allGhostTag.edges.map(item => item.node)

  return (
    <>
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <MetaDataQuery location={location} />
      <Layout langKey={lang} allContentfulFooter={allContentfulFooter}>
        <Container overrideCSS={{ background: `url(${Partciles})` }}>
          <Header />
          <Slugs tags={tags} langKey={lang} />
          {latestPosts.length !== 0 && <LatestArticles posts={latestPosts} />}
          <div
            sx={{
              display: [`block`, `block`, `flex`, `flex`],
              justifyContent: `space-between`,
              flexDirection: [null, null, `row-reverse`, `row-reverse`],
              marginTop: [null, null, `3rem`, `3rem`],
            }}
          >
            {mostPopularPosts.length !== 0 && (
              <div sx={{ width: [`100%`, `100%`, `39%`, `39%`] }}>
                <MostPopular posts={mostPopularPosts} />
              </div>
            )}
            {otherPosts.length !== 0 && (
              <div
                sx={{
                  width: [`100%`, `100%`, `50%`, `50%`],
                  marginBottom: "3rem",
                }}
              >
                <ServicesDeSeelk posts={otherPosts} />
              </div>
            )}
          </div>
          <Pagination pageContext={pageContext} />
        </Container>
      </Layout>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query GhostPostQuery($blogTags: [String!], $lang: String) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { in: $blogTags } } } }
    ) {
      edges {
        node {
          ...GhostPostFields
          featureImageSharp {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allGhostTag(filter: { slug: { in: $blogTags } }) {
      edges {
        node {
          name
          slug
        }
      }
    }
    allContentfulFooter(
      filter: { node_locale: { eq: $lang }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
