// eslint-disable-next-line no-unused-vars
import React from "react"

/** @jsx jsx */
import { jsx } from "theme-ui"

import { useStaticQuery, graphql } from "gatsby"
import Content from "../../content/blog/services-de-seelk.json"
import Article from "./article"
import Img from "gatsby-image"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"

const title = Content[0].title

const ServicesDeSeelk = ({ posts }) => {
  const data = useStaticQuery(graphql`
    query BlogArticleQuery {
      astronauts: file(relativePath: { eq: "astronauts.png" }) {
        childImageSharp {
          fluid(maxWidth: 156) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        sx={{
          justifyContent: `right`,
          marginBottom: `2rem`,
          marginTop: `2rem`,
          display: [`flex`, `flex`, `none`, `none`],
        }}
      >
        <div
          sx={{ width: `50%`, position: `absolute`, top: `-6%`, left: `-10%` }}
        >
          <Img alt="" fluid={data.astronauts.childImageSharp.fluid} />
        </div>
        <div sx={{ width: `100%` }}>
          <h3
            sx={{
              width: `60%`,
              color: colors.code.secondary,
              fontSize: `1.38rem`,
              fontWeight: fontWeights.bold,
              float: `right`,
              display: ["none", "none", "block", "block"],
            }}
          >
            {title}
          </h3>
        </div>
      </div>
      {posts.map(post => (
        <Article key={post.slug} post={post} />
      ))}
    </>
  )
}

export default ServicesDeSeelk
