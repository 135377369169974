// eslint-disable-next-line no-unused-vars
import React from "react"

/** @jsx jsx */
import { jsx } from "theme-ui"
import SlugLink from "./slug-link"

const Slugs = ({ tags, langKey }) => {
  return (
    <div
      sx={{
        display: ["flex", "flex", "flex", "flex"],
        justifyContent: "flex-start",
        maxWidth: "100%",
        flexWrap: "nowrap",
        marginBottom: "3.5rem",
        overflowX: "auto",
      }}
    >
      <div sx={{ marginRight: "0.938rem", marginBottom: "0.938rem" }}>
        <SlugLink langKey={langKey} />
      </div>
      {tags.map(tag => (
        <div
          key={tag.slug}
          sx={{ marginRight: "0.938rem", marginBottom: "0.938rem" }}
        >
          <SlugLink tag={tag} langKey={langKey} />
        </div>
      ))}
    </div>
  )
}

export default Slugs
