// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"

const Tag = ({ children, outlined = false }) => {
  return (
    <span
      sx={{
        fontSize: [`12px`, `12px`, `0.875rem`, `0.875rem`],
        fontWeight: 600,
        textTransform: outlined ? "uppercase" : "capitalize",
        lineHeight: outlined ? `1.79` : "2",
        color: colors.code.primary,
        borderRadius: `5px`,
        padding: outlined ? ['0.5rem', '0.5rem', "15px", "15px"] : "0",
        backgroundColor: outlined ? `rgba(255, 164, 27, 0.07)` : "transparent",
      }}
    >
      {children}
    </span>
  )
}

export default Tag
