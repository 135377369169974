// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import Tag from "./tag"
import { readingTime as readingTimeHelper } from "@tryghost/helpers"
import { Link } from "gatsby"
import Img from "gatsby-image"

export const Info = ({ text }) => (
  <p
    sx={{
      color: colors.code.secondary,
      opacity: 0.3,
      fontSize: `0.78rem`,
      marginRight: `0.2rem`,
    }}
  >
    {text}
  </p>
)

const Article = ({ post }) => {
  const lang = post.tags.find(tag => ["en", "fr"].includes(tag.slug)).slug
  const url =
    lang === "fr" ? `/${lang}/blog/${post.slug}/` : `/blog/${post.slug}/`
  const readingTime = readingTimeHelper(post)

  return (
    <Link sx={{ textDecoration: "none", color: "inherit" }} to={url}>
      <div
        sx={{ display: `flex`, justifyContent: `space-between`, mb: `25px` }}
      >
        <div
          sx={{
            width: `27%`,
            objectFit: `contain`,
          }}
        >
          <Img alt="" fluid={post.featureImageSharp.childImageSharp.fluid} />
        </div>
        <div sx={{ width: `70%` }}>
          <Tag outlined>
            {post.primary_tag ? post.primary_tag.name : post.tags[0].name}
          </Tag>
          <h2
            sx={{
              fontSize: [`0.938rem`, `0.938rem`, `1.375rem`, `1.375rem`],
              fontWeight: `bold`,
              lineHeight: 1.2,
              my: `1rem`,
            }}
          >
            {post.title}
          </h2>
          <p
            sx={{
              opacity: 0.85,
              fontSize: `0.938rem`,
              lineHeight: 1.2,
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
            }}
          >
            {post.excerpt}
          </p>
          <div sx={{ display: `flex` }}>
            <Info text={new Date(post.published_at).toDateString()} />
            <Info text={readingTime} />
            <Info
              text={
                post.primary_author
                  ? post.primary_author.name
                  : post.authors[0].name
              }
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Article
