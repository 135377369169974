// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import LatestArticle from "./latest-article"
import SideArticle from "./side-article"

const LatestArticles = ({ posts }) => {
  return (
    <div
      sx={{
        display: ["block", "block", "flex", "flex"],
        justifyContent: "space-between",
        marginBottom: ["10rem", "14rem", "2rem", "2rem"],
      }}
    >
      <div
        sx={{ marginRight: "3.125rem", width: [`100%`, `100%`, `60%`, `60%`] }}
      >
        <LatestArticle post={posts[0]} />
      </div>
      <div sx={{ width: [`100%`, `100%`, `40%`, `40%`] }}>
        {posts.slice(1).map(post => (
          <SideArticle key={post.id} post={post} />
        ))}
      </div>
    </div>
  )
}

export default LatestArticles
