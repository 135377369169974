// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { colors } from "../../utils/theme/colors"

const SlugLink = ({ tag, langKey }) => {
  return tag ? (
    <Link
      to={
        langKey === `fr` ? `/fr/blog/tag/${tag.slug}` : `/blog/tag/${tag.slug}`
      }
      sx={{
        display: "inline-flex",
        width: "max-content",
        padding: "0.5rem 0.8rem",
        textDecoration: "none",
        color: "#100720",
        backgroundColor: "#fff",
        border: "1px solid rgba(16, 7, 32, 0.1)",
        borderRadius: "5px",
        fontSize: "1rem",
        lineHeight: 1.56,
        fontWeight: 600,
      }}
      activeStyle={{ backgroundColor: colors.code.primary, color: "#fff" }}
    >
      {tag.name || "All"}
    </Link>
  ) : null
}

export default SlugLink
