// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { readingTime as readingTimeHelper } from "@tryghost/helpers"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Tag from "./tag"
import { Info } from "./article"

const LatestArticle = ({ post }) => {
  const lang = post.tags.find(tag => ["en", "fr"].includes(tag.slug)).slug
  const url =
    lang === "fr" ? `/${lang}/blog/${post.slug}/` : `/blog/${post.slug}/`
  const readingTime = readingTimeHelper(post)

  return (
    <Link sx={{ textDecoration: "none", color: "inherit" }} to={url}>
      <div>
        <Img
          alt=""
          sx={{ marginBottom: "1.25rem" }}
          fluid={post.featureImageSharp.childImageSharp.fluid}
        />
        <Tag outlined>
          {post.primary_tag ? post.primary_tag.name : post.tags[0].name}
        </Tag>
        <h2
          sx={{
            fontSize: [`0.938rem`, `0.938rem`, `1.375rem`, `1.375rem`],
            fontWeight: `bold`,
            lineHeight: 1.2,
            my: `1rem`,
            width: [`100%`, `100%`, `70%`, `70%`],
          }}
        >
          {post.title}
        </h2>
        <div sx={{ display: `flex` }}>
          <Info text={new Date(post.published_at).toDateString()} />
          <Info text={readingTime} />
          <Info
            text={
              post.primary_author
                ? post.primary_author.name
                : post.authors[0].name
            }
          />
        </div>
      </div>
    </Link>
  )
}

export default LatestArticle
