// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { readingTime as readingTimeHelper } from "@tryghost/helpers"
import { Link } from "gatsby"

import { colors } from "../../utils/theme/colors"
import Tag from "./tag"
import Img from "gatsby-image"
export const Info = ({ text }) => (
  <p
    sx={{
      color: colors.code.secondary,
      opacity: 0.3,
      fontSize: `0.78rem`,
      marginRight: `0.2rem`,
    }}
  >
    {text}
  </p>
)

const SideArticle = ({ post }) => {
  const lang = post.tags.find(tag => ["en", "fr"].includes(tag.slug)).slug
  const url =
    lang === "fr" ? `/${lang}/blog/${post.slug}/` : `/blog/${post.slug}/`
  const readingTime = readingTimeHelper(post)

  return (
    <Link sx={{ textDecoration: "none", color: "inherit" }} to={url}>
      <div
        sx={{
          display: `flex`,
          justifyContent: ["flex-start", "flex-start", "flex-end", "flex-end"],
          marginBottom: "2.188rem",
        }}
      >
        <div
          sx={{
            marginRight: ["0.938rem", "0.938rem", "1.562rem", "1.562rem"],
            marginTop: "0.5rem",
            objectFit: `contain`,
          }}
        >
          <Img
            alt=""
            fluid={post.featureImageSharp.childImageSharp.fluid}
            sx={{
              width: "6.25rem",
              height: "6.25rem",
              borderRadius: "10px",
            }}
          />
        </div>
        <div sx={{ width: `70%` }}>
          <Tag>
            {post.primary_tag ? post.primary_tag.name : post.tags[0].name}
          </Tag>
          <h2
            sx={{
              fontSize: [`0.938rem`, `0.938rem`, `1.375rem`, `1.375rem`],
              fontWeight: `bold`,
              lineHeight: 1.2,
              marginBottom: "1rem",
            }}
          >
            {post.title}
          </h2>
          <div sx={{ display: `flex` }}>
            <Info text={new Date(post.published_at).toDateString()} />
            <Info text={readingTime} />
            <Info
              text={
                post.primary_author
                  ? post.primary_author.name
                  : post.authors[0].name
              }
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SideArticle
