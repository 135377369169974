// eslint-disable-next-line no-unused-vars
import React from "react"

/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../utils/theme/font-weights"

import { useStaticQuery, graphql } from "gatsby"
import Content from "../../content/blog/most-popular.json"
import Img from "gatsby-image"
import { colors } from "../../utils/theme/colors"
import Content1 from "../../content/blog/services-de-seelk.json"
import SideArticle from "./side-article"

const subtitle = Content1[0].title
const title = Content[0].title

const MostPopular = ({ posts }) => {
  const data = useStaticQuery(graphql`
    query BlogMostPopularQuery {
      img1: file(relativePath: { eq: "mostPopularImg1.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(relativePath: { eq: "mostPopularImg2.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "mostPopularImg3.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      astronauts: file(relativePath: { eq: "astronauts.png" }) {
        childImageSharp {
          fluid(maxWidth: 156) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <h3
        sx={{
          color: colors.code.secondary,
          fontSize: `1.2rem`,
          fontWeight: fontWeights.bold,
          display: [`none`, `none`, `flex`, `flex`],
        }}
      >
        {title}
      </h3>
      {posts.map(post => (
        <SideArticle key={post.slug} post={post} />
      ))}
      <div
        sx={{
          marginTop: `2rem`,
          display: "flex",
          alignItems: `center`,
          position: "relative",
          width: ["100vw", "100%", "100%", "100%"],
          marginLeft: ["-1.812rem", null, null, null],
          backgroundColor: [
            "#f7f7f7",
            "transparent",
            "transparent",
            "transparent",
          ],
        }}
      >
        <Img
          alt=""
          sx={{ width: [`30%`, `30%`, `48%`, `48%`] }}
          fluid={data.astronauts.childImageSharp.fluid}
        />
        <div>
          <p
            sx={{
              fontWeight: fontWeights.black,
              fontSize: `1.38rem`,
              margin: ` auto`,
              width: `75%`,
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </>
  )
}

export default MostPopular
